import React, { forwardRef } from "react";

import { extractAtomsFromProps } from "@dessert-box/core";
import clsx from "clsx";

import { getSprinkles } from "~styles/getSprinkles.css";

import { IconFontAwesome } from "~components/IconFontAwesome";

import { Link } from "../Link";
import { activeClassName, getTagStyles } from "./styles.css";

import type { TagProps } from ".";
import type { LinkProps } from "../Link";
import type { Ref } from "react";
import type { CardInfoProps } from "~components/Card/subComponents/CardInfo";
import type { CardLogosListProps } from "~components/Card/subComponents/CardLogosList";
import type { SvgLogoProps } from "~components/SvgLogo";
import type { VariantThemeEnum } from "~styles/themes/variantThemeColorPalette.css";
import type { StoryblokFieldMedia } from "~types/storyblok.types";

export interface TagLinkProps
  extends TagProps,
    Omit<LinkProps, "color" | "title"> {
  logo?: SvgLogoProps["svgLogo"] | null;
  cover?: StoryblokFieldMedia | null;
  date?: CardInfoProps["date"];
  location?: CardInfoProps["location"];
  logos?: CardLogosListProps["logos"];
  theme?: VariantThemeEnum | null;
  topics?: Array<string> | null;
}

/**
 * Renders a link as a tag component. Incorporates website-specific Gatsby link logic
 * @deprecated `TagLink` should be factored out in favor of passing `Link` to polymorphic tag component
 */
export const TagLink = forwardRef(
  (
    {
      iconLeft,
      iconRight,
      link,
      title,
      ornamentLeft,
      ornamentRight,
      tagSize: size = "sm",
      ...rest
    }: TagLinkProps,
    ref
  ) => {
    const { atomProps, otherProps } = extractAtomsFromProps(rest, getSprinkles);

    const tagClassNames = clsx(getTagStyles({ size }), getSprinkles(atomProps));

    return (
      <Link
        className={tagClassNames}
        ref={ref as Ref<HTMLAnchorElement>}
        link={link}
        partiallyActive
        activeClassName={activeClassName}
        data-testid="tag-link"
        {...otherProps}
      >
        {iconLeft && <IconFontAwesome icon={iconLeft} />}
        {ornamentLeft}

        {title}

        {iconRight && <IconFontAwesome icon={iconRight} />}
        {ornamentRight}
      </Link>
    );
  }
);
